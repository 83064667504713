
















































import { Component, mixins } from 'nuxt-property-decorator';

import { usePageNavigation } from '@/composables/page-navigation';
import { usePageSearch } from '@/composables/page-search';
import { useHeaderColor } from '@/composables/header-color';

import { IconSearch } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconSearch,
  },
})
export default class SiteHeader extends mixins(
  ComposableMixin(usePageNavigation),
  ComposableMixin(usePageSearch),
  ComposableMixin(useHeaderColor),
) {
  toggleSearch () {
    this.pageSearchOpen = !this.pageSearchOpen;
  }
}
