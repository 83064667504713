
























import { Component, Vue } from 'nuxt-property-decorator';

import { IconAngleUp } from '@/icons';

@Component({
  components: {
    IconAngleUp,
  },
})
export default class SiteFooter extends Vue {}
