import { computed, ref, useRoute, watch } from '@nuxtjs/composition-api';

const headerColor = ref(null);
const headerColorInverted = ref(null);

export const useHeaderColor = (_headerColor?: string, _headerColorInverted?: string) => {
  const route = useRoute();
  watch(() => route.value.path, () => {
    headerColor.value = null;
    headerColorInverted.value = null;
  });

  if (_headerColor) {
    headerColor.value = _headerColor;
    headerColorInverted.value = _headerColorInverted;
  }

  const headerPrimaryColor = computed(() => headerColor.value === '#FFF' ? '#7EEF9D' : null);

  return {
    headerColor,
    headerColorInverted,
    headerPrimaryColor,
  };
};
